<template>
  <div class="information_container">
    <div class="work_out" v-if="state">
      个人信息已添加完成，请等待人事部门审核
    </div>
<!--    <div class="welcome">欢迎 {{ inforMation.name }}</div>-->
    <div v-if="!state">
      <h2>嘉美入职登记表</h2>
      <el-row>
        <el-carousel ref="carousel" trigger="click" direction="vertical" :autoplay="false" :loop="false" arrow="always" :height="listheight">
          <el-carousel-item>
            <el-form  :rules="rules" :model="inforMation" label-width="80px" ref="userOneRef">
              <el-form-item label="姓名" style="flex:2" prop='name'>
                <el-input v-model="inforMation.name"></el-input>
              </el-form-item>
              <el-form-item label="政治面貌" style="flex:2">

                  <el-select v-model="inforMation.org" placeholder="请选择">
                    <el-option v-for="(nat, i) in orglist" :key="i" :label="nat" :value="nat"></el-option>
                  </el-select>

              </el-form-item>
              <el-form-item label="联系电话" prop="mobile">

                  <el-input v-model="inforMation.mobile"></el-input>

              </el-form-item>
              <el-form-item label="身份证号" style="flex:3" prop="identity">

                  <el-input v-model="inforMation.identity"></el-input>

              </el-form-item>
              <el-form-item label="现住址" prop="present_address">

                  <el-input v-model="inforMation.present_address"></el-input>

              </el-form-item>
              <el-form-item label="民族" style="flex:1" prop="nation">
                <el-select v-model="inforMation.nation" placeholder="请选择">
                  <el-option v-for="(nat, i) in nations" :key="i" :label="nat" :value="nat"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="籍贯" prop="native_place">

                  <el-cascader size="large" :options="options" v-model="inforMation.native_place" @change="handleChange"></el-cascader>

              </el-form-item>
              <el-form-item label="婚姻状况" prop="marriage">
                <el-select v-model="inforMation.marriage" placeholder="请选择">
                  <el-option v-for="(item, i) in marriages" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学历" prop="education">
                <el-select v-model="inforMation.education" placeholder="请选择学历">
                  <el-option v-for="(item, i) in educations" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="户口性质" prop="household_type">
                <el-select v-model="inforMation.household_type" placeholder="请选择户口性质">
                  <el-option v-for="(item, i) in household" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="驾照类型" prop="license">
                <el-select v-model="inforMation.license" multiple placeholder="请选择(多选)">
                  <el-option v-for="item in licenses" :key="item.value" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服装号码" prop="size_designation">
                <el-select v-model="inforMation.size_designation" placeholder="请选择">
                  <el-option v-for="(item, i) in designations" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="鞋码" prop="footwear_size">
                <el-select v-model="inforMation.footwear_size" placeholder="请选择">
                  <el-option v-for="(item, i) in footwears" :key="i" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="银行卡号" style="flex:3" prop="kahao">

                <el-input v-model.number="inforMation.bank_num"></el-input>

              </el-form-item>

              <el-form-item label="银行名称" style="flex:3">

                <el-select v-model="inforMation.bank_name" placeholder="请选择">
                  <el-option v-for="(item, i) in banklist" :key="i" :label="item.bank" :value="item.bank"></el-option>
                </el-select>

              </el-form-item>
              <el-form-item label="开户行" style="flex:3">

                <el-input v-model="inforMation.bank_addr"></el-input>

              </el-form-item>
            </el-form>
          </el-carousel-item>
          <!-- 紧急联系人 -->
          <el-carousel-item>
            <h3>添加紧急联系人:</h3>
            <el-form  :rules="rules" ref="jinji" :model="emergency_obj" label-width="120px">
              <!-- 渲染已添加的数据 -->
              <el-table v-if="tablenum.jinji" stripe :data="inforMation.emergencys" border style="width: 100%">
                <el-table-column prop="name" label="紧急联系姓名"></el-table-column>
                <el-table-column prop="mobile" label="紧急联系方式"></el-table-column>
              </el-table>
              <div class="information_kuang">
                <el-form-item label="紧急联系人姓名" prop="name">
                  <el-input v-model="emergency_obj.name"></el-input>
                </el-form-item>
                <el-form-item label="紧急联系人电话" prop="mobile">
                  <el-input v-model="emergency_obj.mobile"></el-input>
                </el-form-item>
                <el-button type="primary" round @click="tianjiaren('jinji')">确定</el-button>
              </div>
            </el-form>
          </el-carousel-item>
          <!-- 家庭状况 -->
          <el-carousel-item>
            <h3>家庭状况:</h3>
            <el-form :rules="rules" :model="family_obj" label-width="80px" ref="jiating">
              <!-- 渲染已经添加的内容 -->
              <el-table v-if="tablenum.jiating" stripe :data="inforMation.family_status" border style="width: 100%">
                <el-table-column prop="name" label="姓名" width="100"></el-table-column>
                <el-table-column prop="relation" label="关系：" width="80"></el-table-column>
                <el-table-column prop="birthday" label="出生日期" width="100"></el-table-column>
                <el-table-column prop="station" label="工作单位"></el-table-column>
                <el-table-column prop="mobile" label="联系方式"></el-table-column>
              </el-table>
              <div class="information_kuang">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="family_obj.name"></el-input>
                </el-form-item>
                <el-form-item label="关系" prop="relation">
                  <el-select v-model="family_obj.relation" placeholder="请选择关系">
                    <el-option v-for="(item, i) in relationship" :key="i" :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="出生日期" prop="birthday">
                  <el-date-picker style='width: 100%;' v-model="family_obj.birthday" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>

                <el-form-item label="工作单位" prop="station">
                  <el-input v-model="family_obj.station"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                  <el-input v-model="family_obj.mobile"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-button type="primary" round @click="tianjiaren('jiating')">确定</el-button>
          </el-carousel-item>

          <!-- 学历 -->
          <el-carousel-item>
            <!-- 学历 -->
            <h3>教育经历:</h3>
            <el-form :rules="rules" ref="xueli" :model="educatio_obj" label-width="80px">
              <!-- 渲染填写的学历 -->
              <el-table v-if="tablenum.xueli" stripe :data="inforMation.education_background" border style="width: 100%">
                <el-table-column prop="school" label="毕业院校" width="180"></el-table-column>
                <el-table-column prop="date" label="起止时间" width="180"></el-table-column>
                <el-table-column prop="major" label="所学专业"></el-table-column>
                <el-table-column prop="education" label="学历"></el-table-column>
              </el-table>
              <div class="information_kuang">
                <el-form-item label="毕业院校" prop="school">
                  <el-input v-model="educatio_obj.school"></el-input>
                </el-form-item>
                <el-form-item label="起始时间" prop="date">
                  <el-date-picker
                    style='width: 100%;'
                    value-format="yyyy-MM"
                    v-model='qidatae'
                    type="month"
                    @change="selectChange($event,'学校')"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="截止时间" prop="date">
                  <el-date-picker
                    v-model='zhishijian'
                    style='width: 100%;'
                    value-format="yyyy-MM"
                    type="month"
                    @change="selectChange($event,'学校')"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="所学专业" prop="major">
                  <el-input v-model="educatio_obj.major"></el-input>
                </el-form-item>
                <el-form-item label="学历" prop="education">
                  <el-select v-model="educatio_obj.education" placeholder="请选择学历">
                    <el-option v-for="(item, i) in educations" :key="i" :label="item" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-form>
            <el-button type="primary" round @click="tianjiaren('xueli')">确定</el-button>
          </el-carousel-item>
          <!-- 外语这种类 -->
          <el-carousel-item>
            <h3>外语情况:</h3>
            <el-form ref="waiyu" :model="language_obj" label-width="80px">
              <!-- 渲染已添加的数据 -->
              <el-table v-if="tablenum.waiyu" stripe :data="inforMation.language" border style="width: 100%">
                <el-table-column prop="name" label="外语名称"></el-table-column>
                <el-table-column prop="grade" label="外语等级"></el-table-column>
              </el-table>
              <div class="information_kuang">
                <el-form-item label="外语种类" prop="name">
                  <el-input v-model="language_obj.name"></el-input>
                </el-form-item>
                <el-form-item label="级别" prop="grade">
                  <el-input v-model="language_obj.grade"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-button type="primary" round @click="tianjiaren('waiyu')">确定</el-button>
          </el-carousel-item>
          <!-- 工作经历 -->
          <el-carousel-item>
            <h3>工作经历:</h3>
            <el-form :rules="rules" ref="gongzuo" :model="work_obj" label-width="80px">
              <!-- 渲染已添加的数据 -->
              <el-table v-if="tablenum.gongzuo" stripe :data="inforMation.work_experience" border style="width: 100%">
                <el-table-column prop="date" label="起止时间" width="180"></el-table-column>
                <el-table-column prop="company" label="单位名称" width="180"></el-table-column>
                <el-table-column prop="position" label="职位"></el-table-column>
                <el-table-column prop="remuneration" label="薪资待遇"></el-table-column>
                <el-table-column prop="cause" label="离职原因"></el-table-column>
              </el-table>

              <div class="information_kuang">
                <el-form-item label="起始时间" prop="date">
                  <el-date-picker
                    style='width: 100%;'
                    value-format="yyyy-MM"
                    v-model='qidataes'
                    type="month"
                    @change="selectChange($event,'工作')"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="截止时间" prop="date">
                  <el-date-picker
                    v-model='zhishijians'
                    style='width: 100%;'
                    value-format="yyyy-MM"
                    type="month"
                    @change="selectChange($event,'工作')"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="单位名称" prop="company">
                  <el-input :disabled="nowork" v-model="work_obj.company"></el-input>
                </el-form-item>
                <el-form-item label="职位" prop="position">
                  <el-input :disabled="nowork" v-model="work_obj.position"></el-input>
                </el-form-item>
                <el-form-item label="薪资待遇" prop="remuneration">
                  <el-input :disabled="nowork" v-model.number="work_obj.remuneration"></el-input>
                </el-form-item>
                <el-form-item label="离职原因" prop="cause">
                  <el-input :disabled="nowork" v-model="work_obj.cause"></el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-button type="primary" :disabled="nowork" round @click="tianjiaren('gongzuo')">确定</el-button>
            <el-button type="info" round @click="none()">暂无工作经历</el-button>
          </el-carousel-item>
          <el-carousel-item>
            <el-form ref="form" :model="inforMation" label-width="120px">
              <el-form-item label="受教育经历">
                <el-input type="textarea" v-model="inforMation.education_experience"></el-input>
              </el-form-item>
              <el-form-item label="受奖经历">
                <el-input type="textarea" v-model="inforMation.award"></el-input>
              </el-form-item>
              <el-form-item label="个人特长">
                <el-input type="textarea" v-model="inforMation.personage"></el-input>
              </el-form-item>
            </el-form>
          </el-carousel-item>
        </el-carousel>
      </el-row>
      <div class='onebutton'>  <el-button v-if="complete" type="primary" @click="completed()">完成</el-button>
        <el-button v-if="next_step" type="primary" @click="goll()">下一步</el-button>
        <el-button type="primary" plain @click="gols()">上一步</el-button></div>

    </div>
  </div>
</template>
<script>

/*
* 1.按钮兼容性
*
* */
import { InformationAp, InfoGet, GetBank } from '@/api/register.js'
// 籍贯
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
// 验证
import { rules } from '@/utils/rules'
// 其他
import { nations, licenses, designations, footwears, household, marriages, educations, transformation, relationship, orglist } from '../../utils/human_resources'
// 获取local storage
import { getLocal, steLocal } from '@/utils/local'
export default {
  data() {
    return {
      // 骑士剑
      qidatae: '',
      zhishijian: '',
      qidataes: '',
      zhishijians: '',
      listheight: '1040px',
      // 是否填写过的状态
      state: false,
      // 二级联动
      options: provinceAndCityData,
      // 验证规则
      rules,
      // 民族
      nations,
      // 驾照类型
      licenses,
      // 衣服码
      designations,
      // 鞋码
      footwears,
      // 户口性质
      household,
      // 结婚
      marriages,
      // 学历
      educations,
      // 关系
      relationship,
      // 政治面貌
      orglist,
      // 银行
      banklist: '',
      inforMation: {
        mobile: '', // 电话
        name: '', // 姓名
        nation: '', // 民族
        education: '', // 学历
        marriage: '', // 婚姻状况
        license: '', // 驾照类型
        household_type: '', // 户口性质
        identity: '', // 身份证号
        native_place: [], // 籍贯
        present_address: '', // 现住址
        size_designation: '', // 服装号
        footwear_size: '', // 鞋号
        emergencys: [], // 紧急联系人
        family_status: [], // 家庭状况
        education_background: [], // 学历经历
        language: [], // 语言
        work_experience: [], // 工作经历
        education_experience: '', // 教育（培训）经历
        award: '', // 受奖经历
        personage: '', // 特长
        bank_num: '', // 卡号
        bank_name: '', // 银行
        bank_addr: '', // 开户行
        org: '' // 政治面貌
      },
      tablenum: {
        jinji: false,
        jiating: false,
        xueli: false,
        waiyu: false,
        gongzuo: false
      },
      // 步骤
      index: 0,
      // 完成
      complete: false,
      // 下一步
      next_step: true,

      // 紧急联系人
      emergency_obj: {
        name: '',
        mobile: ''
      },
      // 家庭状况
      family_obj: {
        // 姓名
        name: '',
        // 关系
        relation: '',
        // 生日
        birthday: '',
        // 工作单位
        station: '',
        // 联系方式
        mobile: ''
      },
      // 学历
      educatio_obj: {
        // 学校
        school: '',
        // 起止时间
        date: [],
        // 所学专业
        major: '',
        // 学历
        education: ''
      },

      // 工作经历
      work_obj: {
        // 起止时间
        date: [],
        // 单位名称
        company: '',
        // 职位
        position: '',
        // 薪资待遇
        remuneration: '',
        // 离职原因
        cause: ''
      },
      // 外语
      language_obj: {
        // 名称
        name: '',
        // 等级
        grade: ''
      },

      // 是否有工作经历
      nowork: false
    }
  },
  mounted() {
    // 页面初始化
    // const token = getLocal('token')

    const state = getLocal('state')

    /* if (!token) {
      // 如果没有token去登陆
      // this.$router.push('/login')
      this.state = state
      console.log(typeof this.state)
    } else {

    }*/
    this.state = state === 'true'
    // 获取银行列表
    this.getBankli()
    // this.getInte()
  },

  methods: {
    // 获取信息
    // async getInte() {
    //   const {
    //     data: { data }
    //   } = await InfoGet()
    //
    //   this.inforMation.name = data.name
    //   this.inforMation.mobile = data.mobile
    // },
    // 获取银行列表
    async getBankli() {
      const {
        data: { data }
      } = await GetBank()
      console.log(data)
      this.banklist = data
      // console.log(this.banklist)
    },
    // 请填写必填项
    requiredsd(ulen) {
      if (!ulen) {
        return this.$message.error('请填写必填项')
      } else {
        this.$refs.carousel.next()
        this.index++
      }
    },
    // 下一步
    async goll() {
      switch (this.index) {
        case 0:
          this.$refs.userOneRef.validate(valid => {
            if (!valid) return false
            this.$refs.carousel.next()
            this.index++
          })
          this.listheight = '800px'
          break
        case 1:
          // 紧急联系人
          this.listheight = '800px'
          const emergencysed = this.inforMation.emergencys.length
          this.requiredsd(emergencysed)

          break
        case 2:
          // 家庭状况
          const familyed = this.inforMation.family_status.length
          this.requiredsd(familyed)

          break
        case 3:
          // 学历经历
          const educationed = this.inforMation.education_background.length
          this.requiredsd(educationed)

          break
        case 4:
          // 语言
          const languaged = 1
          this.requiredsd(languaged)

          break
        case 5:
          // 工作经历
          let worked = this.inforMation.work_experience.length
          if (this.nowork) worked = 1

          this.requiredsd(worked)
          this.complete = true
          this.next_step = false
          break
        case 6:
          // 最后
          this.index = 5
          break
      }
    },
    gols() {
      // 上一步
      if (this.index > 0) {
        this.index--
        this.$refs.carousel.prev()
        this.complete = false
        this.next_step = true
      } else {
        this.listheight = '940px'
        return this.$message.warning('已经是第一页')
      }
    },
    // eslint-disable-next-line no-unused-vars
    handleChange(value) {
      // 转译城市
      // console.log(value)
    },
    // 数组前置验证
    verification(data, obj, array) {
      this.$refs[data].validate(valid => {
        if (!valid) return false
        const objs = JSON.parse(JSON.stringify(obj))
        array.push(objs)
        this.tablenum[data] = true
        this.$refs[data].resetFields()
      })
    },
    selectChange(e, r) {
      if (r === '学校') { this.educatio_obj.date.push(e) }
      if (r === '工作') { this.work_obj.date.push(e) }
      console.log(this.educatio_obj.date)
    },
    tianjiaren(time) {
      // 确定按钮
      switch (time) {
        // 添加紧急联系
        case 'jinji':
          const objke = this.emergency_obj
          const arrayke = this.inforMation.emergencys
          this.verification(time, objke, arrayke)
          break
        // 添加家庭信息
        case 'jiating':
          const family_obj = this.family_obj
          const family_status = this.inforMation.family_status
          this.verification(time, family_obj, family_status)
          break
        // 添加学历
        case 'xueli':
          const educatio_obj = this.educatio_obj
          const education_background = this.inforMation.education_background
          console.log(educatio_obj)
          transformation(educatio_obj)
          this.verification(time, educatio_obj, education_background)

          break
        // 添加工作经历
        case 'gongzuo':
          const work_obj = this.work_obj
          const work_experience = this.inforMation.work_experience
          transformation(work_obj)
          this.verification(time, work_obj, work_experience)
          break
        // 添加外语
        case 'waiyu':
          const language_obj = this.language_obj
          const language = this.inforMation.language

          this.verification(time, language_obj, language)
          break
      }
    },
    // 无工作经历
    none() {
      this.nowork = true
    },
    // 完成发送请求
    async completed() {
      const { data } = await InformationAp(this.inforMation)
      // console.log(data)
      if (data.code === 200) {
        this.state = 1
        steLocal('state', 1)
        this.$message.success('添加成功')
        steLocal('token', '')
        steLocal('state', '')
      } else {
        this.$message.error('添加失败,原因:' + data.message)
      }
    }
  }
}
</script>
<style lang="less" scoped>

.information_container {
  width: 75%;
  margin: 0 auto;
  height: 88%;
  h2 {
    text-align: center;
    margin: 0;
    padding: 20px;
  }
  .el-table {
    margin-bottom: 15px;
  }
  .work_out {
    padding: 20px;
    height: 22%;
    background: #d9edf7;
    border-radius: 12px;
    width: 60%;
    margin: 0 auto;
    position: absolute;
    top: 25%;
    line-height: 2;
    text-align: center;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
.fomelist {
  display: flex;
}
.welcome {
  position: fixed;
  right: 10%;
  height: 30%;
  top: 20px;
}
</style>
